@import "normalize.css";
@import "@blueprintjs/core/lib/css/blueprint.css";
@import "@blueprintjs/icons/lib/css/blueprint-icons.css";

body {
  margin: 0px;
}

.container {
  padding: 10px;
  max-width: 1000px;
  margin: auto;
  min-height: 100vh;

  background: #FEFEFE;
}